<template>
  <v-col>
    <add-proxy />
    <proxy-list />
  </v-col>
</template>
<script>
  import AddProxy from '../components/proxyPage/AddProxy.vue';
  import ProxyList from '../components/proxyPage/ProxyList.vue';
  export default {
    name: 'ProxyPage',
    components: {
      ProxyList,
      AddProxy,
    },

    methods: {
      save () {

      },

    },
  };
</script>
<style lang="scss">
.row-wrap{
  display: flex;
  flex-flow: row wrap;
}
.gap-2{
  gap: 16px
}
</style>
